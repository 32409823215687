import React from 'react'
import Strings from 'project/localisation'
import Card from './base/Card'
import Multiline from 'components/util/Multiline'
import { ReactFC } from 'common/types/ReactFC'
import classNames from 'classnames'
import imageResize from 'project/image-proxy-web' // we need this to make JSX compile

type NoSetupFeesCardType = {
  className?: string
  text?: string
  rounded?: boolean
}

const NoSetupFeesCard: ReactFC<NoSetupFeesCardType> = ({
  className = 'no-setup-text',
  rounded = false,
  text = Strings.noSetupFees,
}) => {
  return (
    <div className={'col-lg-6 mb-md-0 mb-2'}>
      <Card
        padding='lg'
        theme='gradient-pink'
        className={classNames(
          'd-flex justify-content-center align-items-center pb-xxl-5 pb-4 pt-11',
          rounded ? 'rounded-box' : '',
        )}
      >
        <img
          loading='lazy'
          alt={`${text} icon`}
          className='img-fluid icon__absolute-top-right'
          width={123}
          height={123}
          src={imageResize(
            require('public/images/icons/homepage-hm-symbol-desktop.svg'),
          )}
        />
        <h3 className={classNames('text-light', className)}>
          <Multiline text={`${text}`.split('\n').join('\n')} />
        </h3>
      </Card>
    </div>
  )
}

export default NoSetupFeesCard
