import { FC } from 'react'

type MostPopularRibbonType = {}

const MostPopularRibbon: FC<MostPopularRibbonType> = ({}) => {
  return (
    <>
      <svg
        className='d-none d-md-block'
        xmlns='http://www.w3.org/2000/svg'
        width='218'
        height='218'
        viewBox='0 0 218 218'
        fill='none'
      >
        <path
          d='M159.578 0.578125L217.078 58.0781L217.078 217.234L0.421875 0.578125H159.578Z'
          fill='#3EB8E5'
        />
        <path
          d='M103.331 81.5781L97.4873 62.5361H97.3379C97.5482 66.4098 97.6533 68.9941 97.6533 70.2891V81.5781H93.0547V57.3066H100.061L105.805 75.8672H105.904L111.997 57.3066H119.003V81.5781H114.205V70.0898C114.205 69.5475 114.211 68.9222 114.222 68.2139C114.244 67.5055 114.321 65.624 114.454 62.5693H114.305L108.046 81.5781H103.331Z'
          fill='white'
        />
        <path
          d='M146.159 69.4092C146.159 73.4268 145.163 76.5146 143.171 78.6729C141.179 80.8311 138.323 81.9102 134.604 81.9102C130.886 81.9102 128.03 80.8311 126.038 78.6729C124.046 76.5146 123.05 73.4157 123.05 69.376C123.05 65.3363 124.046 62.2539 126.038 60.1289C128.041 57.9928 130.908 56.9248 134.638 56.9248C138.368 56.9248 141.217 57.9984 143.188 60.1455C145.169 62.2926 146.159 65.3805 146.159 69.4092ZM128.445 69.4092C128.445 72.1208 128.96 74.1628 129.989 75.5352C131.019 76.9076 132.557 77.5938 134.604 77.5938C138.711 77.5938 140.764 74.8656 140.764 69.4092C140.764 63.9417 138.722 61.208 134.638 61.208C132.59 61.208 131.046 61.8997 130.006 63.2832C128.965 64.6556 128.445 66.6976 128.445 69.4092Z'
          fill='white'
        />
        <path
          d='M164.517 74.8379C164.517 77.0293 163.725 78.7559 162.143 80.0176C160.571 81.2793 158.38 81.9102 155.568 81.9102C152.979 81.9102 150.688 81.4232 148.695 80.4492V75.668C150.333 76.3984 151.717 76.9131 152.846 77.2119C153.986 77.5107 155.026 77.6602 155.967 77.6602C157.096 77.6602 157.959 77.4443 158.557 77.0127C159.165 76.5811 159.47 75.9391 159.47 75.0869C159.47 74.611 159.337 74.1904 159.071 73.8252C158.806 73.4489 158.413 73.0892 157.893 72.7461C157.383 72.403 156.338 71.8551 154.755 71.1025C153.272 70.4053 152.16 69.7357 151.418 69.0938C150.676 68.4518 150.084 67.7048 149.642 66.8525C149.199 66.0003 148.978 65.0042 148.978 63.8643C148.978 61.7171 149.702 60.0293 151.152 58.8008C152.613 57.5723 154.628 56.958 157.195 56.958C158.457 56.958 159.658 57.1074 160.798 57.4062C161.949 57.7051 163.15 58.1257 164.4 58.668L162.74 62.6689C161.445 62.1377 160.372 61.7669 159.52 61.5566C158.678 61.3464 157.848 61.2412 157.029 61.2412C156.055 61.2412 155.308 61.4681 154.788 61.9219C154.268 62.3757 154.008 62.9678 154.008 63.6982C154.008 64.152 154.113 64.5505 154.323 64.8936C154.534 65.2256 154.866 65.5521 155.319 65.873C155.784 66.1829 156.874 66.7474 158.59 67.5664C160.859 68.651 162.414 69.7412 163.255 70.8369C164.096 71.9215 164.517 73.2552 164.517 74.8379Z'
          fill='white'
        />
        <path
          d='M177.279 81.5781H172.133V61.5898H165.542V57.3066H183.87V61.5898H177.279V81.5781Z'
          fill='white'
        />
        <path
          d='M120.575 89.6211C120.575 91.2611 120.038 92.5179 118.963 93.3916C117.889 94.2653 116.36 94.7021 114.377 94.7021H112.744V100.578H110.176V84.873H114.699C116.661 84.873 118.129 85.2741 119.103 86.0762C120.084 86.8783 120.575 88.0599 120.575 89.6211ZM112.744 92.543H114.108C115.426 92.543 116.393 92.3138 117.008 91.8555C117.624 91.3971 117.932 90.681 117.932 89.707C117.932 88.8047 117.657 88.1315 117.105 87.6875C116.554 87.2435 115.694 87.0215 114.527 87.0215H112.744V92.543Z'
          fill='white'
        />
        <path
          d='M137.455 92.7041C137.455 95.2607 136.814 97.248 135.532 98.666C134.257 100.084 132.449 100.793 130.107 100.793C127.737 100.793 125.914 100.091 124.639 98.6875C123.372 97.2767 122.738 95.2751 122.738 92.6826C122.738 90.0902 123.375 88.0993 124.65 86.71C125.932 85.3206 127.758 84.626 130.129 84.626C132.463 84.626 134.268 85.3314 135.543 86.7422C136.818 88.153 137.455 90.1403 137.455 92.7041ZM125.467 92.7041C125.467 94.6377 125.857 96.1058 126.637 97.1084C127.418 98.1038 128.575 98.6016 130.107 98.6016C131.633 98.6016 132.782 98.1074 133.555 97.1191C134.336 96.1309 134.726 94.6592 134.726 92.7041C134.726 90.7777 134.34 89.3167 133.566 88.3213C132.8 87.3258 131.654 86.8281 130.129 86.8281C128.589 86.8281 127.425 87.3258 126.637 88.3213C125.857 89.3167 125.467 90.7777 125.467 92.7041Z'
          fill='white'
        />
        <path
          d='M151.026 89.6211C151.026 91.2611 150.489 92.5179 149.415 93.3916C148.341 94.2653 146.812 94.7021 144.828 94.7021H143.195V100.578H140.628V84.873H145.15C147.113 84.873 148.581 85.2741 149.555 86.0762C150.536 86.8783 151.026 88.0599 151.026 89.6211ZM143.195 92.543H144.56C145.877 92.543 146.844 92.3138 147.46 91.8555C148.076 91.3971 148.384 90.681 148.384 89.707C148.384 88.8047 148.108 88.1315 147.557 87.6875C147.005 87.2435 146.146 87.0215 144.979 87.0215H143.195V92.543Z'
          fill='white'
        />
        <path
          d='M166.274 84.873V95.0352C166.274 96.1953 166.023 97.2122 165.522 98.0859C165.028 98.9525 164.308 99.6221 163.363 100.095C162.424 100.56 161.3 100.793 159.989 100.793C158.042 100.793 156.527 100.277 155.446 99.2461C154.364 98.2148 153.823 96.7969 153.823 94.9922V84.873H156.402V94.8096C156.402 96.1058 156.702 97.0618 157.304 97.6777C157.905 98.2936 158.829 98.6016 160.075 98.6016C162.496 98.6016 163.706 97.3304 163.706 94.7881V84.873H166.274Z'
          fill='white'
        />
        <path
          d='M170.091 100.578V84.873H172.659V98.376H179.308V100.578H170.091Z'
          fill='white'
        />
        <path
          d='M191.558 100.578L189.99 96.2168H183.985L182.449 100.578H179.742L185.618 84.8086H188.411L194.287 100.578H191.558ZM189.313 94.0039L187.841 89.7285C187.734 89.4421 187.584 88.9909 187.39 88.375C187.204 87.7591 187.075 87.3079 187.004 87.0215C186.81 87.9023 186.527 88.8656 186.155 89.9111L184.737 94.0039H189.313Z'
          fill='white'
        />
        <path
          d='M198.727 92.1777H200.511C201.707 92.1777 202.573 91.9557 203.11 91.5117C203.647 91.0677 203.916 90.4089 203.916 89.5352C203.916 88.6471 203.626 88.0098 203.046 87.623C202.466 87.2363 201.592 87.043 200.425 87.043H198.727V92.1777ZM198.727 94.3047V100.578H196.16V84.873H200.596C202.623 84.873 204.123 85.2526 205.097 86.0117C206.071 86.7708 206.558 87.9167 206.558 89.4492C206.558 91.4043 205.541 92.7972 203.508 93.6279L207.944 100.578H205.022L201.262 94.3047H198.727Z'
          fill='white'
        />
        <path
          d='M161 109.578L165.608 119.235L176.217 120.634L168.456 128.001L170.405 138.522L161 133.418L151.595 138.522L153.544 128.001L145.783 120.634L156.392 119.235L161 109.578Z'
          fill='white'
        />
        <path
          d='M196 116.578L198.016 120.803L202.657 121.415L199.262 124.638L200.115 129.241L196 127.008L191.885 129.241L192.738 124.638L189.343 121.415L193.984 120.803L196 116.578Z'
          fill='white'
        />
        <path
          d='M77 8.57812L83.6243 22.4605L98.8743 24.4707L87.7184 35.0607L90.5191 50.1855L77 42.8481L63.4809 50.1855L66.2816 35.0607L55.1257 24.4707L70.3757 22.4605L77 8.57812Z'
          fill='white'
        />
        <path
          d='M137 24.5781L141.032 33.0283L150.315 34.2519L143.524 40.698L145.229 49.9044L137 45.4381L128.771 49.9044L130.476 40.698L123.685 34.2519L132.968 33.0283L137 24.5781Z'
          fill='white'
        />
        <path
          d='M164.5 17.5781L166.66 22.105L171.633 22.7605L167.995 26.2138L168.908 31.1458L164.5 28.7531L160.092 31.1458L161.005 26.2138L157.367 22.7605L162.34 22.105L164.5 17.5781Z'
          fill='white'
        />
        <path
          d='M195.5 147.578L199.964 156.934L210.241 158.288L202.723 165.425L204.611 175.618L195.5 170.673L186.389 175.618L188.277 165.425L180.759 158.288L191.036 156.934L195.5 147.578Z'
          fill='white'
        />
      </svg>
      <svg
        className='d-block d-sm-none'
        width='105'
        height='105'
        viewBox='0 0 105 105'
        fill='none'
      >
        <mask
          id='mask0_4978_16611'
          style='mask-type:alpha'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='105'
          height='105'
        >
          <path
            d='M0 0H90C98.2843 0 105 6.71573 105 15V105H0V0Z'
            fill='#D9D9D9'
          />
        </mask>
        <g mask='url(#mask0_4978_16611)'>
          <path
            d='M-2 -1.2959L47.704 -50.9999L156.838 58.1343L107.134 107.838L-0.704102 -3.62072e-08L-2 -1.2959Z'
            fill='#3EB8E5'
          />
        </g>
        <path
          d='M44.8418 34L41.4043 22.7988H41.3164C41.4401 25.0775 41.502 26.5977 41.502 27.3594V34H38.7969V19.7227H42.918L46.2969 30.6406H46.3555L49.9395 19.7227H54.0605V34H51.2383V27.2422C51.2383 26.9232 51.2415 26.5553 51.248 26.1387C51.2611 25.722 51.3066 24.6152 51.3848 22.8184H51.2969L47.6152 34H44.8418Z'
          fill='white'
        />
        <path
          d='M69.623 26.8418C69.623 29.2051 69.0371 31.0215 67.8652 32.291C66.6934 33.5605 65.0137 34.1953 62.8262 34.1953C60.6387 34.1953 58.959 33.5605 57.7871 32.291C56.6152 31.0215 56.0293 29.1986 56.0293 26.8223C56.0293 24.446 56.6152 22.6328 57.7871 21.3828C58.9655 20.1263 60.6517 19.498 62.8457 19.498C65.0397 19.498 66.7161 20.1296 67.875 21.3926C69.0404 22.6556 69.623 24.472 69.623 26.8418ZM59.2031 26.8418C59.2031 28.4368 59.5059 29.638 60.1113 30.4453C60.7168 31.2526 61.6217 31.6562 62.8262 31.6562C65.2415 31.6562 66.4492 30.0514 66.4492 26.8418C66.4492 23.6257 65.248 22.0176 62.8457 22.0176C61.6413 22.0176 60.7331 22.4245 60.1211 23.2383C59.5091 24.0456 59.2031 25.2467 59.2031 26.8418Z'
          fill='white'
        />
        <path
          d='M80.0098 30.0352C80.0098 31.3242 79.5443 32.3398 78.6133 33.082C77.6888 33.8242 76.3997 34.1953 74.7461 34.1953C73.2227 34.1953 71.875 33.9089 70.7031 33.3359V30.5234C71.6667 30.9531 72.4805 31.2559 73.1445 31.4316C73.8151 31.6074 74.4271 31.6953 74.9805 31.6953C75.6445 31.6953 76.1523 31.5684 76.5039 31.3145C76.862 31.0605 77.041 30.6829 77.041 30.1816C77.041 29.9017 76.9629 29.6543 76.8066 29.4395C76.6504 29.2181 76.4193 29.0065 76.1133 28.8047C75.8138 28.6029 75.1986 28.2806 74.2676 27.8379C73.3952 27.4277 72.7409 27.0339 72.3047 26.6562C71.8685 26.2786 71.5202 25.8392 71.2598 25.3379C70.9993 24.8366 70.8691 24.2507 70.8691 23.5801C70.8691 22.3171 71.2956 21.3242 72.1484 20.6016C73.0078 19.8789 74.1927 19.5176 75.7031 19.5176C76.4453 19.5176 77.1517 19.6055 77.8223 19.7812C78.4993 19.957 79.2057 20.2044 79.9414 20.5234L78.9648 22.877C78.2031 22.5645 77.5716 22.3464 77.0703 22.2227C76.5755 22.099 76.0872 22.0371 75.6055 22.0371C75.0326 22.0371 74.5931 22.1706 74.2871 22.4375C73.9811 22.7044 73.8281 23.0527 73.8281 23.4824C73.8281 23.7493 73.89 23.9837 74.0137 24.1855C74.1374 24.3809 74.3327 24.5729 74.5996 24.7617C74.873 24.944 75.5143 25.276 76.5234 25.7578C77.8581 26.3958 78.7728 27.0371 79.2676 27.6816C79.7624 28.3197 80.0098 29.1042 80.0098 30.0352Z'
          fill='white'
        />
        <path
          d='M87.1055 34H84.0781V22.2422H80.2012V19.7227H90.9824V22.2422H87.1055V34Z'
          fill='white'
        />
        <path
          d='M54.1129 39.0234C54.1129 39.918 53.8199 40.6035 53.234 41.0801C52.648 41.5566 51.8141 41.7949 50.732 41.7949H49.8414V45H48.441V36.4336H50.9078C51.9781 36.4336 52.7789 36.6523 53.3102 37.0898C53.8453 37.5273 54.1129 38.1719 54.1129 39.0234ZM49.8414 40.6172H50.5855C51.3043 40.6172 51.8316 40.4922 52.1676 40.2422C52.5035 39.9922 52.6715 39.6016 52.6715 39.0703C52.6715 38.5781 52.5211 38.2109 52.2203 37.9688C51.9195 37.7266 51.4508 37.6055 50.8141 37.6055H49.8414V40.6172Z'
          fill='white'
        />
        <path
          d='M63.2293 40.7051C63.2293 42.0996 62.8797 43.1836 62.1805 43.957C61.4852 44.7305 60.4988 45.1172 59.2215 45.1172C57.9285 45.1172 56.9344 44.7344 56.2391 43.9688C55.5477 43.1992 55.202 42.1074 55.202 40.6934C55.202 39.2793 55.5496 38.1934 56.2449 37.4355C56.9441 36.6777 57.9402 36.2988 59.2332 36.2988C60.5066 36.2988 61.491 36.6836 62.1863 37.4531C62.8816 38.2227 63.2293 39.3066 63.2293 40.7051ZM56.6902 40.7051C56.6902 41.7598 56.9031 42.5605 57.3289 43.1074C57.7547 43.6504 58.3855 43.9219 59.2215 43.9219C60.0535 43.9219 60.6805 43.6523 61.1023 43.1133C61.5281 42.5742 61.741 41.7715 61.741 40.7051C61.741 39.6543 61.5301 38.8574 61.1082 38.3145C60.6902 37.7715 60.0652 37.5 59.2332 37.5C58.3934 37.5 57.7586 37.7715 57.3289 38.3145C56.9031 38.8574 56.6902 39.6543 56.6902 40.7051Z'
          fill='white'
        />
        <path
          d='M70.541 39.0234C70.541 39.918 70.248 40.6035 69.6621 41.0801C69.0762 41.5566 68.2422 41.7949 67.1602 41.7949H66.2695V45H64.8691V36.4336H67.3359C68.4062 36.4336 69.207 36.6523 69.7383 37.0898C70.2734 37.5273 70.541 38.1719 70.541 39.0234ZM66.2695 40.6172H67.0137C67.7324 40.6172 68.2598 40.4922 68.5957 40.2422C68.9316 39.9922 69.0996 39.6016 69.0996 39.0703C69.0996 38.5781 68.9492 38.2109 68.6484 37.9688C68.3477 37.7266 67.8789 37.6055 67.2422 37.6055H66.2695V40.6172Z'
          fill='white'
        />
        <path
          d='M78.7668 36.4336V41.9766C78.7668 42.6094 78.6301 43.1641 78.3566 43.6406C78.0871 44.1133 77.6945 44.4785 77.1789 44.7363C76.6672 44.9902 76.0539 45.1172 75.3391 45.1172C74.2766 45.1172 73.4504 44.8359 72.8605 44.2734C72.2707 43.7109 71.9758 42.9375 71.9758 41.9531V36.4336H73.382V41.8535C73.382 42.5605 73.5461 43.082 73.8742 43.418C74.2023 43.7539 74.7062 43.9219 75.3859 43.9219C76.7062 43.9219 77.3664 43.2285 77.3664 41.8418V36.4336H78.7668Z'
          fill='white'
        />
        <path
          d='M80.7582 45V36.4336H82.1586V43.7988H85.7855V45H80.7582Z'
          fill='white'
        />
        <path
          d='M92.3766 45L91.5211 42.6211H88.2457L87.4078 45H85.9313L89.1363 36.3984H90.6598L93.8648 45H92.3766ZM91.152 41.4141L90.3492 39.082C90.2906 38.9258 90.2086 38.6797 90.1031 38.3438C90.0016 38.0078 89.9313 37.7617 89.8922 37.6055C89.7867 38.0859 89.6324 38.6113 89.4293 39.1816L88.6559 41.4141H91.152Z'
          fill='white'
        />
        <path
          d='M96.1961 40.418H97.1688C97.8211 40.418 98.2938 40.2969 98.5867 40.0547C98.8797 39.8125 99.0262 39.4531 99.0262 38.9766C99.0262 38.4922 98.868 38.1445 98.5516 37.9336C98.2352 37.7227 97.7586 37.6172 97.1219 37.6172H96.1961V40.418ZM96.1961 41.5781V45H94.7957V36.4336H97.2156C98.3211 36.4336 99.1395 36.6406 99.6707 37.0547C100.202 37.4688 100.468 38.0938 100.468 38.9297C100.468 39.9961 99.9129 40.7559 98.8035 41.209L101.223 45H99.6297L97.5789 41.5781H96.1961Z'
          fill='white'
        />
        <path
          d='M87 51L90.7442 58.5448L99.3637 59.6373L93.0582 65.3927L94.6412 73.6127L87 69.625L79.3588 73.6127L80.9418 65.3927L74.6363 59.6373L83.2558 58.5448L87 51Z'
          fill='white'
        />
        <path
          d='M29.5 3L31.9481 8.13044L37.584 8.87336L33.4612 12.7871L34.4962 18.3766L29.5 15.665L24.5038 18.3766L25.5388 12.7871L21.416 8.87336L27.0519 8.13044L29.5 3Z'
          fill='white'
        />
        <path
          d='M91 5L92.7281 8.62149L96.7063 9.1459L93.7961 11.9085L94.5267 15.8541L91 13.94L87.4733 15.8541L88.2039 11.9085L85.2937 9.1459L89.2719 8.62149L91 5Z'
          fill='white'
        />
      </svg>
    </>
  )
}

export default MostPopularRibbon
