import { FC } from 'react'
import { TPlanSummary } from 'common/cms-types'
import PlanSummary from './PlanSummary'
import classNames from 'classnames'

type PlanListType = {
  plans: TPlanSummary[]
  className?: string
}

const PlanList: FC<PlanListType> = ({
  className = 'mt-lg-10 mt-12 mb-lg-17 mb-8',
  plans,
}) => {
  return (
    <div className={classNames(className, 'row plan-list gy-4')}>
      {plans?.map((plan) => (
        <div key={plan.slug} className='col-xl-6'>
          <PlanSummary plan={plan} />
        </div>
      ))}
    </div>
  )
}

export default PlanList
